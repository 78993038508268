import './Summary.css'
import { useAppDispatch, useAppSelector } from "../hooks";
import { useState } from 'react';
import { IconButton, InputAdornment, Snackbar, TextField } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { selectComputedValues } from '../State/taxSelector';

function formatNumber(value: number): string {
    return value % 1 === 0 ? value.toString() : value.toFixed(2);
}

export default function Summary()
{
    //loading computed values from redux Selector
    const computedValues = useAppSelector(selectComputedValues);

    const [alertVisible, setAlertVisible] = useState(false);
    
    function handleCopy(value: string)
    {
        navigator.clipboard.writeText(value);
        setAlertVisible(true);
    }
    
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setAlertVisible(false);
    };

    return (
        <>
            <div className="summaryCard">
                <div className="summaryCardHeader">
                    <h2 style={{paddingLeft: '10px'}}>Summary</h2>
                </div>
                <div className="summaryCardBody">
                    <div className='cardRow'>
                        <h3>National insurance:</h3>
                        <TextField 
                            size='small' margin='none' className='summaryTextField'
                            value={formatNumber(computedValues.nationalInsurance)}
                            type="number"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className='cardRow'>
                        <h3>Tax:</h3>
                        <TextField 
                            size='small' margin='none' className='summaryTextField'
                            value={formatNumber(computedValues.tax)}
                            type="number"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className='cardRow'>
                        <h3>COLA / BONUS:</h3>
                        <TextField 
                            size='small' margin='none' className='summaryTextField'
                            value={formatNumber(computedValues.cola)}
                            type="number"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className='cardRow'>
                        <h3>Total Net Income:</h3>
                        <TextField 
                            size='small' margin='none' className='summaryTextField'
                            value={formatNumber(computedValues.totalNetIncome)}
                            style={{fontWeight: 'bold'}}
                            type="number"
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" style={{padding: 0}} 
                                            onClick={() => handleCopy(formatNumber(computedValues.totalNetIncome))}>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
            <Snackbar
                open={alertVisible}
                autoHideDuration={3000}
                message="Copied to clipboard"
                onClose={handleClose}
            />
        </>
    )
}