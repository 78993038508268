import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';

interface taxBracket {
    from: number,
    to: number,
    rate: number,
}

type RelationshipStatus = 'single' | 'married' | 'parent';

function taxCalculation(totalIncome: number, relationshipStatus : RelationshipStatus)
{
    let tax = 0;
    let brackets : taxBracket[] = [];

    if ( relationshipStatus === 'single' )
    {
        brackets = [
            { from: 0, to: 9100, rate: 0},
            { from: 9101, to: 14500, rate: 0.15},
            { from: 14501, to: 19500, rate: 0.25},
            { from: 19501, to: 60000, rate: 0.25},
            { from: 60001, to: Infinity, rate: 0.35},
        ];
    }
    else if ( relationshipStatus === 'married' )
    {
        brackets = [
            { from: 0, to: 12700, rate: 0},
            { from: 12701, to: 21200, rate: 0.15},
            { from: 21201, to: 28700, rate: 0.25},
            { from: 28701, to: 60000, rate: 0.25},
            { from: 60001, to: Infinity, rate: 0.35},
            ];
    }
    else if ( relationshipStatus === 'parent' )
    {
        brackets = [
            { from: 0, to: 10500, rate: 0},
            { from: 10501, to: 15800, rate: 0.15},
            { from: 15801, to: 21200, rate: 0.25},
            { from: 21201, to: 60000, rate: 0.25},
            { from: 60001, to: Infinity, rate: 0.35},
            ];
    }
    
    for (const bracket of brackets) {
    const { from, to, rate } = bracket;
        if (totalIncome > from) {
            const taxableIncome = Math.min(totalIncome, to) - from;
            tax += taxableIncome * rate;
        }
    }
    
    return tax;
    
}

function nationalInsuranceCalculation (totalIncome: number, checkedStudent: boolean, checked18Older: boolean, checkedBefore1961: boolean )
{
    let nationalInsurance = 0;
    const weeklyWage = totalIncome/52;
    if( checkedStudent )
    {
        //student

        if( checked18Older )
        {
            //18+

            if( weeklyWage * 0.10 > 7.94 )
            {
                nationalInsurance = 7.94 * 52;
            }
            else
            {
                //if they earn less than the treshold we just charge it at 10%
                nationalInsurance = (weeklyWage * 0.10) * 52;
            }
        }
        else
        {
            //18-

            if( weeklyWage * 0.10 > 4.38 )
            {
                nationalInsurance = 4.38 * 52;
            }
            else
            {
                //if they earn less than the treshold we just charge it at 10%
                nationalInsurance = (weeklyWage * 0.10) * 52;
            }
        }
    }
    else
    {
        //not student

        if( weeklyWage >= 213.55 ) //if persons weekly wage is higher or equal to 213.55 it doesnt matter if they are 18+ or 18-
        {
            if( checkedBefore1961 )
            {
                //before dec 31st 1961 individuals get lower NI contribution 
                if( weeklyWage <= 423.07 )
                {
                    nationalInsurance = (weeklyWage * 0.10) * 52;
                }
                else
                {
                    nationalInsurance = 42.31 * 52;
                }
            }
            else
            {
                //after 1961 individuals get higher NI contribution 
                if( weeklyWage <= 532.28 )
                {
                    nationalInsurance = (weeklyWage * 0.10) * 52;
                }
                else
                {
                    nationalInsurance = 53.23 * 52;
                }
            }
            
        }
        else //for people earning under 213.55 a week, their NI is split based on 18+ and 18-
        {
            if( checked18Older )
            {
                //18+
                nationalInsurance = 21.35 * 52;
            }
            else
            {
                //18-
                nationalInsurance = 6.62 * 52;
            }
        }

    }
    return nationalInsurance;
}

export const selectDetails = (state: RootState) => state.details;

export const selectComputedValues = createSelector(
  [selectDetails],
  (details) => {
    const { totalIncome, checked18Older, checkedStudent, checkedBefore1961, relationshipStatus } = details;
    
    if( relationshipStatus === 'single' || relationshipStatus === 'married' || relationshipStatus === 'parent' )
    {
        const tax = taxCalculation(totalIncome, relationshipStatus );

        const nationalInsurance = nationalInsuranceCalculation(totalIncome, checkedStudent, checked18Older, checkedBefore1961);
        const cola = 512.52; // paid every 3 months €121.16 + €135.10 + €121.16 + €135.10
        
        // Calculate total net income
        const totalNetIncome = totalIncome - tax - nationalInsurance + cola;

        return {
            tax,
            nationalInsurance,
            cola,
            totalNetIncome,
        };
    }
    else
    {
        return {
            tax: 0,
            nationalInsurance: 0,
            cola: 0,
            totalNetIncome: 0,
        };
    }
    
  }
);

export {};