import './Breakdown.css'
import { useAppSelector } from '../hooks';
import { selectComputedValues } from '../State/taxSelector';

function formatNumber(value: number): string {
    return value % 1 === 0 ? value.toString() : value.toFixed(2);
}

export default function Breakdown()
{
    const grossIncome = useAppSelector(state => state.details.totalIncome);
    const grossValues = {
        weekly: formatNumber(grossIncome / 52),
        monthly: formatNumber(grossIncome / 12),
        yearly: formatNumber(grossIncome),
    }
    //loading computed values from redux Selector
    const computedValues = useAppSelector(selectComputedValues);

    const yearlyValues = {
        nationalInsurance: formatNumber(computedValues.nationalInsurance),
        tax: formatNumber(computedValues.tax),
        cola: formatNumber(computedValues.cola),
        totalNetIncome: formatNumber(computedValues.totalNetIncome),
    }

    const monthlyValues = 
    {
        nationalInsurance: formatNumber(computedValues.nationalInsurance / 12),
        tax: formatNumber(computedValues.tax / 12),
        cola: formatNumber(computedValues.cola / 12),
        totalNetIncome: formatNumber(computedValues.totalNetIncome / 12),
    }
    
    const weeklyValues = 
    {
        nationalInsurance: formatNumber(computedValues.nationalInsurance / 52),
        tax: formatNumber(computedValues.tax / 52),
        cola: formatNumber(computedValues.cola / 52),
        totalNetIncome: formatNumber(computedValues.totalNetIncome / 52),
    }

    return (
        <>
            {/* Desktop Breakdown */}
            <div className="breakdownDesktop">
                <div className="breakdownCard">
                    <div className="breakdownCardHeader">
                        <h2 style={{paddingLeft: '10px'}}>Full Breakdown</h2>
                    </div>
                    <div className="breakdownCardBody">
                        <table className="breakdownTable">
                            <thead>
                                <tr>
                                    <th>Period</th>
                                    <th>Weekly</th>
                                    <th>Monthly</th>
                                    <th>Yearly</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Gross Income</td>
                                    <td className='greenText'>+ {grossValues.weekly}</td>
                                    <td className='greenText'>+ {grossValues.monthly}</td>
                                    <td className='greenText'>+ {grossValues.yearly}</td>
                                </tr>
                                <tr>
                                    <td>National Insurance</td>
                                    <td className='redText'>- {weeklyValues.nationalInsurance}</td>
                                    <td className='redText'>- {monthlyValues.nationalInsurance}</td>
                                    <td className='redText'>- {yearlyValues.nationalInsurance}</td>
                                </tr>
                                <tr>
                                    <td>Income Tax</td>
                                    <td className='redText'>- {weeklyValues.tax}</td>
                                    <td className='redText'>- {monthlyValues.tax}</td>
                                    <td className='redText'>- {yearlyValues.tax}</td>
                                </tr>
                                <tr>
                                    <td>COLA / BONUS</td>
                                    <td className='greenText'>+ {weeklyValues.cola}</td>
                                    <td className='greenText'>+ {monthlyValues.cola}</td>
                                    <td className='greenText'>+ {yearlyValues.cola}</td>
                                </tr>
                                <tr className="netSalaryRow">
                                    <td>Net Salary</td>
                                    <td>{weeklyValues.totalNetIncome}</td>
                                    <td>{monthlyValues.totalNetIncome}</td>
                                    <td>{yearlyValues.totalNetIncome}</td>
                                </tr>
                            </tbody>
                        </table>    
                    </div>
                </div>
            </div>

            {/* Mobile Breakdown */}
            <div className="breakdownMobile">
                <div className="breakdownCard">
                    <div className="breakdownCardHeader">
                        <h2 style={{paddingLeft: '10px'}}>Weekly Breakdown</h2>
                    </div>
                    <div className="breakdownCardBody">
                        <table className="breakdownTable">
                            <tbody>
                                <tr>
                                    <td>Gross Income</td>
                                    <td className='greenText'>+ {grossValues.weekly}</td>
                                </tr>
                                <tr>
                                    <td>National Insurance</td>
                                    <td className='redText'>- {weeklyValues.nationalInsurance}</td>
                                </tr>
                                <tr>
                                    <td>Income Tax</td>
                                    <td className='redText'>- {weeklyValues.tax}</td>
                                </tr>
                                <tr>
                                    <td>COLA / BONUS</td>
                                    <td className='greenText'>+ {weeklyValues.cola}</td>
                                </tr>
                                <tr className="netSalaryRow">
                                    <td>Net Salary</td>
                                    <td>{weeklyValues.totalNetIncome}</td>
                                </tr>
                            </tbody>
                        </table>    
                    </div>
                </div>

                <div className="breakdownCard">
                    <div className="breakdownCardHeader">
                        <h2 style={{paddingLeft: '10px'}}>Monthly Breakdown</h2>
                    </div>
                    <div className="breakdownCardBody">
                        <table className="breakdownTable">
                            <tbody>
                                <tr>
                                    <td>Gross Income</td>
                                    <td className='greenText'>+ {grossValues.monthly}</td>
                                </tr>
                                <tr>
                                    <td>National Insurance</td>
                                    <td className='redText'>- {monthlyValues.nationalInsurance}</td>
                                </tr>
                                <tr>
                                    <td>Income Tax</td>
                                    <td className='redText'>- {monthlyValues.tax}</td>
                                </tr>
                                <tr>
                                    <td>COLA / BONUS</td>
                                    <td className='greenText'>+ {monthlyValues.cola}</td>
                                </tr>
                                <tr className="netSalaryRow">
                                    <td>Net Salary</td>
                                    <td>{monthlyValues.totalNetIncome}</td>
                                </tr>
                            </tbody>
                        </table>    
                    </div>
                </div>

                <div className="breakdownCard">
                    <div className="breakdownCardHeader">
                        <h2 style={{paddingLeft: '10px'}}>Yearly Breakdown</h2>
                    </div>
                    <div className="breakdownCardBody">
                        <table className="breakdownTable">
                            <tbody>
                                <tr>
                                    <td>Gross Income</td>
                                    <td className='greenText'>+ {grossValues.yearly}</td>
                                </tr>
                                <tr>
                                    <td>National Insurance</td>
                                    <td className='redText'>- {yearlyValues.nationalInsurance}</td>
                                </tr>
                                <tr>
                                    <td>Income Tax</td>
                                    <td className='redText'>- {yearlyValues.tax}</td>
                                </tr>
                                <tr>
                                    <td>COLA / BONUS</td>
                                    <td className='greenText'>+ {yearlyValues.cola}</td>
                                </tr>
                                <tr className="netSalaryRow">
                                    <td>Net Salary</td>
                                    <td>{yearlyValues.totalNetIncome}</td>
                                </tr>
                            </tbody>
                        </table>    
                    </div>
                </div>
            </div>
        </>
    )
}