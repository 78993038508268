import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { blogPosts } from '../data/blogPosts';

const Blogs: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = (slug: string) => {
        navigate(`/blogs/${slug}`);
    };
   
    const canonicalUrl = window.location.href;

    return (
        <div className="blogs-container">
            <Helmet>
                <title>Tax Calculation Blogs | Malta Tax Calculator</title>
                <meta name="description" content="Explore our collection of tax calculation blogs for insights on Malta's tax system and financial planning." />
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:title" content="Tax Calculation Blogs | Malta Tax Calculator" />
                <meta property="og:description" content="Explore our collection of tax calculation blogs for insights on Malta's tax system and financial planning." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={canonicalUrl} />
            </Helmet>
            <h1>Tax Calculation Blogs</h1>
            <div className="blog-list">
                {blogPosts.map(post => (
                    <div key={post.id} className="blog-preview" onClick={() => handleClick(post.slug)}>
                        <h2><Link to={`/blogs/${post.slug}`}>{post.title}</Link></h2>
                        <p>{post.summary}</p>
                        <small>{post.date}</small>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blogs;