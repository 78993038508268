import './Details.css'
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setChecked18Older, setCheckedBefore1961, setCheckedStudent, setRelationshipStatus, setTotalIncome } from '../State/slices/detailsSlice';

export default function Details()
{

    const totalIncome = useAppSelector((state) => state.details.totalIncome);
    const checked18Older = useAppSelector((state) => state.details.checked18Older);
    const checkedStudent = useAppSelector((state) => state.details.checkedStudent);
    const checkedBefore1961 = useAppSelector((state) => state.details.checkedBefore1961);
    const relationshipStatus = useAppSelector((state) => state.details.relationshipStatus);

    const dispatch = useAppDispatch();

    const [sliderTotalIncome, setSliderTotalIncome] = useState<number>(totalIncome); //used to avoid spamming taxSelector computed calculation when user is dragging slider.

    return (
        <>
            <div className="detailsCard">
                <div className="detailsCardHeader">
                    <h2 style={{paddingLeft: '10px'}}>Enter your details</h2>
                </div>
                <div className="detailsCardBody">
                    <div className='cardRow'>
                        <h3>Total Income</h3>
                        <div className='cardColumn'>
                            <TextField 
                                size='small' margin='none' 
                                value={totalIncome}
                                type="number"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch(setTotalIncome(parseFloat(event.target.value)));
                                    setSliderTotalIncome(parseFloat(event.target.value)); //updating slider value if user updates the value from the text field.
                                }}
                            />
                            <Slider
                                aria-label="Small"
                                size="small"
                                defaultValue={totalIncome}
                                min={1}
                                max={150000}
                                valueLabelDisplay="auto"
                                value={sliderTotalIncome}
                                onChange={(event: Event, newValue: number | number[]) => {
                                    setSliderTotalIncome(newValue as number); //this set the slider value, not the actual totalIncome which is watched by the tax Selector.
                                }}
                                onChangeCommitted={(event: React.SyntheticEvent  | Event, newValue: number | number[]) => {
                                    //triggers once user lets go of the slider and commits value
                                    dispatch(setTotalIncome(newValue as number)); //we then set the actual totalIncome to trigger the tax Selector once
                                    setSliderTotalIncome(newValue as number); //syncing the slider value
                                }}
                            />
                        </div>
                        
                    </div>
                    <div className='cardRow'>
                        <h3>Are you 18+ ?</h3>
                        <Checkbox 
                            checked={checked18Older} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                dispatch(setChecked18Older(event.target.checked));
                            }}
                        />
                    </div>
                    <div className='cardRow'>
                        <h3>Are you a student ?</h3>
                        <Checkbox 
                            checked={checkedStudent} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                dispatch(setCheckedStudent(event.target.checked));
                            }}
                        />
                    </div>
                    <div className='cardRow'>
                        <h3>Were you born before 1962 ?</h3>
                        <Checkbox 
                            checked={checkedBefore1961} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                dispatch(setCheckedBefore1961(event.target.checked));
                            }}
                        />
                    </div>
                    <div className='cardRow'>
                        <h3>Relationship status:</h3>
                        <FormControl component="fieldset">
                            <RadioGroup 
                            aria-label="relationshipstatus" 
                            name="relationshipstatus" 
                            
                            row
                            value={relationshipStatus} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                dispatch(setRelationshipStatus(event.target.value));
                            }}
                            >
                                <FormControlLabel value="single" control={<Radio />} label="Single" />
                                <FormControlLabel value="married" control={<Radio />} label="Married" />
                                <FormControlLabel value="parent" control={<Radio />} label="Parent" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>
        </>
    )
}