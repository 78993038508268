import React, {useEffect} from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import './App.css';
import { Helmet } from 'react-helmet';
import Details from './Components/Details';
import Summary from './Components/Summary';
import Breakdown from './Components/Breakdown';
import Blogs from './Components/Blogs';
import BlogPost from './Components/BlogPost';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return null;
}

function Calculator() {
  const canonicalUrl = window.location.href;
  return (
    <>
      <Helmet>
        <title>Malta Salary Tax Calculator</title>
        <meta name="description" content="Income tax calculator for Malta, updated for 2024"/>
        <meta property="og:title" content="Malta Salary Tax Calculator" />
        <meta property="og:description" content="Income tax calculator for Malta, updated for 2024" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className='header'>
        <h1>Malta Income Tax Calculator</h1>
        <h4>Calculate your taxes with ease</h4>
      </div>
      <div className="mainContainer">
        <div className='mainRow'>
          <Details />
          <Summary />
        </div>
        <Breakdown />
        <div>
          <div className='disclaimerCard'>
            <div className='disclaimerCardBody'>
              <h3>This Salary Income Tax Calculator for Malta has been updated in 2024 using the latest Malta National Insurance Contributions (NI),
                and the Tax Rates for 2024 on Chargeable Income (€). COLA/Bonus is payed out every 3 months, but is included in the period totals of 
                this calculator (March: €121.16 + June: €135.10 + September: €121.16 + December: €135.10)
              </h3>
              <h4><span style={{color: 'orange'}}>Disclaimer:</span> This tax calculator tool is updated for 2024 and should be accurate, however always consult with a chartered accountant
                for definitive tax calculations to make your tax returns and do not use these values as guaranteed correct due to bonuses, benefits,
                and other factors which may affect the totals.
              </h4>
              <h4>If you would like to calculate your taxes yourself you can use the following resources: </h4>
              <ul>
                <li><a href='https://cfr.gov.mt/en/rates/Pages/TaxRates/Tax-Rates-2024.aspx' target='_blank'>Tax Rates</a></li>
                <li><a href='https://cfr.gov.mt/en/rates/Pages/SSC1/SSC1-2024.aspx' target='_blank'>Class 1 Social Security Contributions</a></li>
                <li><a href='https://dier.gov.mt/en/Employment-Conditions/Wages/Pages/Bonus-and-Weekly-Allowances.aspx' target='_blank'>Bonus and Weekly Allowances</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function App() {
  const location = useLocation();
  return (
    <div>
      <ScrollToTop />
      <nav className="navbar">
        <ul>
          <li>
            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
          </li>
          <li>
            <Link to="/blogs" className={location.pathname === '/blogs' ? 'active' : ''}>Blogs</Link>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={<Calculator />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<BlogPost />} />
      </Routes>
    </div>
  );
}

export default App;