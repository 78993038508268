import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { blogPosts } from '../data/blogPosts';

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const post = blogPosts.find(post => post.slug === slug);

  if (!post) {
    return <div>Blog post not found</div>;
  }

  const canonicalUrl = window.location.href;

  return (
    <div className="blog-post">
      <Helmet>
        <title>{post.title} | Malta Tax Calculator Blog</title>
        <meta name="description" content={post.summary} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.summary} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <h1>{post.title}</h1>
      <small>{post.date}</small>
      <ReactMarkdown>{post.content}</ReactMarkdown>
      <Link to="/blogs">Back to all blogs</Link>
    </div>
  );
};

export default BlogPost;