import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DetailsState
{
    totalIncome: number;
    checked18Older: boolean;
    checkedStudent: boolean;
    checkedBefore1961: boolean;
    relationshipStatus: string;
}

const initialState : DetailsState = {
    totalIncome: 15000,
    checked18Older: true,
    checkedStudent: false,
    checkedBefore1961: false,
    relationshipStatus: "single",
}

const detailsSlice = createSlice({
    name: "details",
    initialState,
    reducers: { //reducers are like event handlers (action handlers)
        setTotalIncome: (state, action: PayloadAction<number>) => {
            state.totalIncome = action.payload;
        },
        setChecked18Older: (state, action: PayloadAction<boolean>) => {
            state.checked18Older = action.payload;
        },
        setCheckedStudent: (state, action: PayloadAction<boolean>) => {
            state.checkedStudent = action.payload;
        },
        setCheckedBefore1961: (state, action: PayloadAction<boolean>) => {
            state.checkedBefore1961 = action.payload;
        },
        setRelationshipStatus: (state, action: PayloadAction<string>) => {
            if( ["single", "married", "parent"].indexOf(action.payload) )
            {
                state.relationshipStatus = action.payload; 
            }
            else
            {
                state.relationshipStatus = "single"; 
            }
        }
    }
});


export const { setTotalIncome, setChecked18Older, setCheckedStudent, setCheckedBefore1961, setRelationshipStatus } = detailsSlice.actions;

export default detailsSlice.reducer;